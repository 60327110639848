import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer"
import FacilityUI from '../components/FacilityUI';
import Topbar from '../components/Topbar';

const Facility = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | InfraStructure">
            <Topbar />
            <NavOne />
            <PageHeader title="Facility" />
            <FacilityUI />
            <Footer />
        </Layout>
    );
};

export default Facility;
