import React, { useState, useEffect } from 'react'
import canteen from "../assets/images/canteen.jpeg";
import buses from "../assets/images/buses.jpg";
import medical from "../assets/images/Medical_Attention.jpg";

const FacilityUI = () => {
    const PUBLIC_URL = process.env.REACT_APP_APIURL

    return (
        <div>
            <section className='team-details' style={{paddingBottom:"0px"}}>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='team-one__single'>
                                    <div className='team-one__image'>
                                        <img className="facilityImg"
                                            src={
                                                buses
                                            }
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='team-details__content'>
                                    <h2 className='team-details__title title'>
                                        Bus Service
                                    </h2>
                                    <p className='team-details__text'>
                                        Shanti Asiatic School offers bus services for hassle free transportation to the school. The
                                        school possesses wide spread bus pick up and drop off points for convenient commute.
                                        Great facilities are available to our students at Shanti Asiatic School. While on the road,
                                        our students are well-cared for by our school bus staff. The school buses are kept in good
                                        condition, have professional drivers who have undergone extensive training, and have
                                        numerous safety guards to keep the kids safe. Drivers and bus staff pick up and drop off
                                        students safely by adhering to all safety regulations and well-planned route networks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='team-details' style={{paddingBottom:"0px"}}>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='team-details__content'>
                                    <h2 className='team-details__title title'>
                                        Canteen Facility
                                    </h2>
                                    <p className='team-details__text'>
                                        We support our students' healthy eating practises by providing a nourishing canteen
                                        facility and uphold their dietary habits. High standards of hygiene are followed in the
                                        well-equipped kitchen to ensure children's safety. We give our students access to a variety
                                        of wholesome foods and instruct them in developing habits of sharing with their friends.
                                        Our kitchen's chefs are skilled professionals who adhere to all safety guidelines.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='team-one__single'>
                                    <div className='team-one__image'>
                                        <img className="facilityImg"
                                            src={
                                                canteen
                                            }
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='team-details' style={{paddingBottom:"0px"}}>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='team-one__single'>
                                    <div className='team-one__image'>
                                        <img className="facilityImg"
                                            src={
                                                medical
                                            }
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='team-details__content'>
                                    <h2 className='team-details__title title'>
                                        Medical Attention
                                    </h2>
                                    <p className='team-details__text'>
                                        We have a medical facility on site where we can treat our students in an emergency with
                                        qualified medical personnel. Our students receive prompt medical attention from the
                                        medical staff, who follow proper first aid procedures in case of injuries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FacilityUI
